//import { Isotope } from "isotope-layout/OrbitControls";

class Vaultlist {

    constructor(container) {


        let container2 = document.querySelector('.grid');

        let artworks = container2.querySelectorAll(".artwork");

        for (let i = 0; i < artworks.length; i++) {
            artworks[i].image = artworks[i].querySelector("img")
            artworks[i].ratio = artworks[i].image.dataset.ratio;

            artworks[i].image.style.width = Math.floor(artworks[i].children[0].children[0].clientWidth) + "px";
            artworks[i].image.style.height = (Math.floor(artworks[i].children[0].children[0].clientWidth) / artworks[i].image.dataset.ratio) - 2 + "px";
        }

        setTimeout(() => {
            let iso = new Isotope( container2, {
                // options
                itemSelector: '.grid-item',
                masonry: {
                    // use element for option
                    columnWidth: '.grid-item'
                  }
            });
        }, 1000);

        
    
    }


}

export { Vaultlist };
