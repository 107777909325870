
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'


import { VideoElement, VideoElementSnippets } from "@realthings/videoplayer/VideoElement"

class RevealOnScroll {

		constructor(type, container, containerAttributes) {

			this.type = type;
			this.container = container;
			this.containerAttributes = containerAttributes;

			this.browserHeight = window.innerHeight;
			this.hideInitially();
			this.scrollThrottle = throttle(this.calcCaller, 200).bind(this);
			this.events();

			if (this.type == 'videoelement') {
				console.log(this.container)
				// if (this.container.classList.contains("hasVideoSnippets")) {
				// 	this.player = new VideoElementSnippets(this.container, this.containerAttributes);
				// } else {
					this.player = new VideoElement(this.container, this.containerAttributes);
				//}
				
				this.player.prepare();
			}

			this.calculateIfScrolledTo(this.container);
			
		}

		events() {
			window.addEventListener("scroll", this.scrollThrottle);
			window.addEventListener("resize", debounce(() => {
				console.log("resize")
				this.browserHeight = window.innerHeight;
			}, 333))
		}

		calcCaller() {
			if (this.container.isRevealed == false) {
				this.calculateIfScrolledTo(this.container);
			}
		}

		calculateIfScrolledTo(el) {

			if (window.scrollY + this.browserHeight > el.getBoundingClientRect().top || 400 > el.getBoundingClientRect().top) {

				el.classList.add("reveal-item--is-visible");
			
				if (this.container.isRevealed == false) {
					console.log(this.type)
					//if (this.type == 'model3D')	this.container = new Model3D(this.container, this.containerAttributes);
					//if (this.type == 'slideshow')	this.container = new Slideshow(this.container, this.containerAttributes);;
					if (this.type == 'videoelement') {
						this.player.init();
					}	
				}
				this.container.isRevealed = true;
				window.removeEventListener("scroll", this.scrollThrottle)
			}
		}

		hideInitially() {
			this.container.classList.add("reveal-item");
			this.container.isRevealed = false;
		}

}

export { RevealOnScroll }
