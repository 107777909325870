

class OpenMobileMenu {

    constructor() {
        this.menuButton = document.getElementById("menu-mobile-button")
        this.menuContainer = document.querySelector(".mainmenu");
        this.events()
    }

    events() {
        this.menuButton.addEventListener("click", () => this.toggleTheMenu())
        
       
    }

    toggleTheMenu() {
        document.body.classList.remove("schedule-act");
        if (!this.menuContainer.classList.contains("mainmenu--is-visible")) {
            this.openMenu();
        } else {
            this.closeMenu();
        }
    }

    openMenu() {
        this.menuContainer.classList.add("mainmenu--is-visible")
        this.menuButton.innerHTML = "<div>Close</div>";
    }

    closeMenu() {
        this.menuContainer.classList.remove("mainmenu--is-visible")
        this.menuButton.innerHTML = "<div>Menu</div>";
    }

    checkClickOutside(event) {
        setTimeout(() => {
            if (!this.menuContainer.contains(event.target) && !this.menuButton.contains(event.target) && this.menuContainer.classList.contains("main-navigation__container--is-visible")) {
                this.closeMenu()  
            }
        },20)
    }
}

export default OpenMobileMenu


