class ScrollListener {

	constructor(elem) {

        this.containerToScroll = document.querySelector(".exhib-content__descr_text");
        this.metaContainer = document.querySelector(".exhib-content__meta > div");
		this.headlines;

		let i = 0;
		document.querySelectorAll('section').forEach(elem => { 
			elem.setAttribute("data-section", i); 
			elem.setAttribute("id", "section-" + i);
			i++; 
		}) 

		// this.options = {
		// 	root: document.querySelector('.exhib-content__descr_text'),
		// 	rootMargin: '200px',
		// 	threshold: .5
		//   }

		   this.buildSubMenu();

		//   const callback = (entries, observer) => {
		// 	for (const entry of entries) {

		// 		
		// 	}
		//   }

		// const observer = new IntersectionObserver(callback, this.options);

		// document.querySelectorAll('section').forEach(elem => { observer.observe(elem) })

		const observer = new IntersectionObserver(entries => {
			let count = 0;
			entries.forEach(entry => {

				const { section } = entry.target.dataset
				const tab = document.querySelector(`a[data-section='${section}']`)
				
				tab.classList.toggle('active', entry.isIntersecting)
			})
		  },{
		  	root: null,
			rootMargin: '-5% 0px -80% 0px',
			threshold: this.buildThresholdList()
		});

		document.querySelectorAll('section').forEach(elem => { observer.observe(elem) })

        

		this.events();

    }

	events() {
		window.addEventListener("scroll", () => this.animateScroll())
	}

    buildSubMenu() {

        this.headlines = this.containerToScroll.querySelectorAll("h2");

        let submenu = "";
        for (let i = 0; i < this.headlines.length; i++) {
            submenu += "<li><a href='#section-" + i + "' data-section='" + i + "'>" + this.headlines[i].innerHTML + "</a></li>";
        }

        if (this.headlines.length > 1) {
            this.metaContainer.insertAdjacentHTML("afterbegin", "<div class='submenu'><div class='submenu_header'>On This Page</div><ul>" + submenu + "</ul>");
        }
		this.submenu = this.metaContainer.querySelector(".submenu");
		this.firstElem = this.metaContainer.querySelector(".submenu ul > li:first-child a");

    }

	buildThresholdList() {
		let thresholds = [];
		let numSteps = 20;
	  
		for (let i = 1.0; i <= numSteps; i++) {
		  let ratio = i / numSteps;
		  thresholds.push(ratio);
		}
	  
		thresholds.push(0);
		return thresholds;
	  }

	

	

	animateScroll() {

		if (window.scrollY < window.innerHeight) {
			console.log(this.firstElem)
			this.firstElem.classList.add("active");
		}
	}
	// 	let lastDistTop = -99999;
	// 	let nextDistTop = 99999;

	// 	for (let i = 0; i < this.headlines.length; i++) {
			
	// 		let currDistTop = this.headlines[i].getBoundingClientRect().top;

			
	// 		if (i > 0) {
	// 			lastDistTop = this.headlines[i-1].getBoundingClientRect().top;
	// 		} else {
	// 			lastDistTop = 0;
	// 		}

	// 		if (i < (this.headlines.length-1)) {
	// 			nextDistTop = this.headlines[i + 1].getBoundingClientRect().top
	// 		} else {
	// 			nextDistTop = 99999;
				
	// 		};
	// 		console.log(this.headlines[i].innerHTML + " CurrDistTop: " + currDistTop + " NextDistTop: " + nextDistTop + " LastDistTop: " + lastDistTop)


	// 		if (currDistTop < nextDistTop ) {
	// 			this.submenu.children[i].classList.add("submenu-act");
	// 		} else {
	// 			this.submenu.children[i].classList.remove("submenu-act");
	// 		}
	
	// 	}

	// 	// if (this.elem.scrollLeft > window.innerWidth*.15) {
	// 	// 	this.containerToScroll.scroll({
	// 	// 		top: 200,
	// 	// 		behavior: "smooth"
	// 	// 	});
	// 	// } else {
	// 	// 	this.containerToScroll.scroll({
	// 	// 		top: 0,
	// 	// 		behavior: "smooth"
	// 	// 	});
	// 	// }
	// }

}

export default ScrollListener