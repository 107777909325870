class Cookies {

	constructor(elem) {

		this.cookieButton = document.getElementById("cookieclose");


		this.cookieButton.addEventListener("click", ()  => {
			document.body.classList.remove('cookiePolicy-shown');
			this.createCookie('cookieOK','1',99);
			
		})

		var gotCookie = this.readCookie('cookieOK');
		if (gotCookie == 1) {
			document.body.classList.remove('cookiePolicy-shown');
		}

	}

	createCookie(name,value,days) {
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			var expires = "; expires="+date.toGMTString();
		}
		else var expires = "";
		document.cookie = name+"="+value+expires+"; path=/";
	}
	
	readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}
	
	eraseCookie(name) {
		createCookie(name,"",-1);
	}

}

export default Cookies