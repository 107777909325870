"use strict";

import OpenMobileMenu from "../js/modules/OpenMobileMenu.js"

import { Mapbox } from "../blocks/mapbox/mapbox.js"

import { Vaultlist } from "../blocks/vault-list/Vaultlist.js"

import { RevealOnScroll } from "../js/modules/RevealOnScroll.js"

import {Slideshow} from "../js/modules/Slideshow.js"

import Marquee from "../js/modules/Marquee"

import Cookies from './Cookies'

import ScrollListener from './modules/ScrollListener'

import '@google/model-viewer';


const planetContainer = document.querySelector("#planetContainer")
if (planetContainer != null) { let planet = new Sketch(planetContainer); }

const globeContainer = document.querySelector("#globeContainer")
if (globeContainer != null) { let globe = new Globe(globeContainer); }

const menuContainer = document.getElementById("primary-menu");

new Marquee();

new OpenMobileMenu();

const mapboxContainer = document.getElementById("map");
if (mapboxContainer != null) { const mapbox = new Mapbox(mapboxContainer) };

// const artworks3dContainer = document.getElementById("artworks3d");
// const artworks3d = new Artworks3d(artworks3dContainer);

document.addEventListener("DOMContentLoaded", function () {
	const videoElements = Array.from(document.getElementsByClassName("videoelement"));
	for (let i = 0; i < videoElements.length; i++) {
		new RevealOnScroll('videoelement', videoElements[i], {});
	}

	new Cookies();
	
});


const standardSliderSettings = {
	direction: 'horizontal',
	loop: true,
	slidesPerView: 4,
	spaceBetween: 0,
	centeredSlides: true,
	autoplay: false,
	effect: 'move',
	watchSlidesProgress: false,
	preventClicksPropagation : true,
	preventClicks: true,
	simulateTouch: true,
	pagination: {
		el: '.swiper-pagination > div',
		type: 'progressbar',
	},
	autoHeight: true,
	slideClass: "swiper-slide"
}

const regularSliderSettings = {...standardSliderSettings};
regularSliderSettings.slidesPerView = 1;
regularSliderSettings.effect = 'fade';
regularSliderSettings.slideClass = 'wp-block-image';
regularSliderSettings.hasArrows = true;
//alert(regularSliderSettings.effect)
const regularSliders = Array.from(document.getElementsByClassName("regularGallery"));
for (let i = 0; i < regularSliders.length; i++) {
	new Slideshow(regularSliders[i], false, regularSliderSettings);
}


const slideshowheadertextSettings = {...standardSliderSettings};
slideshowheadertextSettings.autoHeight = true;
slideshowheadertextSettings.direction = 'vertical';
slideshowheadertextSettings.spaceBetween = 0;
slideshowheadertextSettings.pagination = false;
slideshowheadertextSettings.autoplay = { delay: 3500};
slideshowheadertextSettings.centeredSlides = false;
slideshowheadertextSettings.slidesPerView = 1;
slideshowheadertextSettings.loop = true;
slideshowheadertextSettings.captions = null;
const slideshowheadertext = Array.from(document.getElementsByClassName("slideshowheadertext"));

for (let i = 0; i < slideshowheadertext.length; i++) {
	new Slideshow(slideshowheadertext[i], false, slideshowheadertextSettings);
}
const slideshowquotesSettings = {...standardSliderSettings};
slideshowquotesSettings.autoHeight = false;
slideshowquotesSettings.direction = 'horizontal';
slideshowquotesSettings.spaceBetween = 0;
slideshowquotesSettings.pagination = false;
slideshowquotesSettings.autoplay = { delay: 3500};
slideshowquotesSettings.centeredSlides = false;
slideshowquotesSettings.slidesPerView = 1;
slideshowquotesSettings.loop = true;
slideshowquotesSettings.captions = null;
const slideshowquotes = Array.from(document.getElementsByClassName("slideshowquotes"));

for (let i = 0; i < slideshowquotes.length; i++) {
	new Slideshow(slideshowquotes[i], false, slideshowquotesSettings);
}


if (document.body.classList.contains('home')) {
	
	document.addEventListener('scroll', scrollCheck, true);
	
	
	function scrollCheck() {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
			document.body.classList.add("scrolled");
		  } else {
			document.body.classList.remove("scrolled");
		  }
	}
}

const artworksliderSettings = {...standardSliderSettings};
artworksliderSettings.autoHeight = true;
artworksliderSettings.spaceBetween = 0;
artworksliderSettings.pagination = false;
artworksliderSettings.autoplay = false;
artworksliderSettings.centeredSlides = false;
artworksliderSettings.slidesPerView = 3;
artworksliderSettings.loop = true;
artworksliderSettings.captions = null;
artworksliderSettings.preloadImages = true;
artworksliderSettings.lazy = true;
artworksliderSettings.hasArrows = true;
const artworkslider = Array.from(document.getElementsByClassName("artworkslider"));

for (let i = 0; i < artworkslider.length; i++) {
	new Slideshow(artworkslider[i], false, artworksliderSettings);
}


const programmsliderSettings = standardSliderSettings;
programmsliderSettings.direction = 'horizontal';
programmsliderSettings.spaceBetween = 0;
programmsliderSettings.autoplay = false;
programmsliderSettings.slidesPerView = 3;
programmsliderSettings.loop = true;
programmsliderSettings.captions = null;
programmsliderSettings.hasArrows = true;
//programmsliderSettings.slideClass = 'programme__event';
const programmslider = Array.from(document.getElementsByClassName("programmslider"));

for (let i = 0; i < programmslider.length; i++) {
	new Slideshow(programmslider[i], false, programmsliderSettings);
}


const isotopes = Array.from(document.getElementsByClassName("grid"));

for (let i = 0; i < isotopes.length; i++) {
	new Vaultlist(isotopes[i]);
}

if (document.body.classList.contains("single-experience") || document.body.classList.contains("page-id-246")) {
	new ScrollListener();
}
