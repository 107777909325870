

class Mapbox {

    constructor() {

        mapboxgl.accessToken = 'pk.eyJ1IjoiYXJ0LW9uLWVhcnRoIiwiYSI6ImNrcmx0aG41ODA1OGwydXJxNDFud201OGEifQ.inLNSj8HnGSk2ukD5U_HEQ';
        let map = (window.map = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/light-v10',
            zoom: 18,
            center: [13.40075017312205,52.5006442356146],
            pitch: 60,
            antialias: true // create the gl context with MSAA antialiasing, so custom layers are antialiased
        }));

        // parameters to ensure the model is georeferenced correctly on the map
        var modelOrigin = [13.40075017312205,52.5006442356146];
        var modelAltitude = 0;
        var modelRotate = [Math.PI / 2, 0, 0];

        var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
            modelOrigin,
            modelAltitude
        );

        // transformation parameters to position, rotate and scale the 3D model onto the map
        var modelTransform = {
            translateX: modelAsMercatorCoordinate.x,
            translateY: modelAsMercatorCoordinate.y,
            translateZ: modelAsMercatorCoordinate.z,
            rotateX: modelRotate[0],
            rotateY: modelRotate[1],
            rotateZ: modelRotate[2],
            /* Since our 3D model is in real world meters, a scale transform needs to be
            * applied since the CustomLayerInterface expects units in MercatorCoordinates.
            */
            scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits()*300
        };

        var THREE = window.THREE;

        // configuration of the custom layer for a 3D model per the CustomLayerInterface
        var customLayer = {
            id: '3d-model',
            type: 'custom',
            renderingMode: '3d',
            onAdd: function (map, gl) {
                this.camera = new THREE.Camera();
                this.scene = new THREE.Scene();

                // create two three.js lights to illuminate the model
                // var directionalLight = new THREE.DirectionalLight(0xffffff);
                // directionalLight.position.set(0, -70, 100).normalize();
                // this.scene.add(directionalLight);

                // var directionalLight2 = new THREE.DirectionalLight(0xffffff);
                // directionalLight2.position.set(0, 70, 100).normalize();
                // this.scene.add(directionalLight2);

                const light = new THREE.AmbientLight( 0xffffff); // soft white light
                this.scene.add( light );

                // use the three.js GLTF loader to add the 3D model to the three.js scene
                var loader = new THREE.GLTFLoader();
                loader.load(
                    '../wp-content/themes/onearth/blocks/mapbox/210729_koenig_surpr_web.glb',
                    function (gltf) {
                        this.scene.add(gltf.scene);
                    }.bind(this)
                );
                this.map = map;

                // use the Mapbox GL JS map canvas for three.js
                this.renderer = new THREE.WebGLRenderer({
                    canvas: map.getCanvas(),
                    context: gl,
                    antialias: true
                });

                this.renderer.autoClear = false;
            },
            render: function (gl, matrix) {
                var rotationX = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(1, 0, 0),
                    modelTransform.rotateX
                );
                var rotationY = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 1, 0),
                    modelTransform.rotateY
                );
                var rotationZ = new THREE.Matrix4().makeRotationAxis(
                    new THREE.Vector3(0, 0, 1),
                    modelTransform.rotateZ
                );

                var m = new THREE.Matrix4().fromArray(matrix);
                var l = new THREE.Matrix4()
                    .makeTranslation(
                        modelTransform.translateX,
                        modelTransform.translateY,
                        modelTransform.translateZ
                    )
                    .scale(
                        new THREE.Vector3(
                            modelTransform.scale,
                            -modelTransform.scale,
                            modelTransform.scale
                        )
                    )
                    .multiply(rotationX)
                    .multiply(rotationY)
                    .multiply(rotationZ);

                this.camera.projectionMatrix = m.multiply(l);
                this.renderer.resetState();
                this.renderer.render(this.scene, this.camera);
                this.map.triggerRepaint();
            }
        };

        map.on('style.load', function () {
            map.addLayer(customLayer, '');
        });
    }
}

export { Mapbox }