import Marquee3k from 'marquee3000'

class Marquee {

    constructor(elem) {
       setTimeout(function() {let elem = Marquee3k.init()},100);
    }

}

export default Marquee