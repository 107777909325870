//import 'swiper/css';
import {Swiper, Autoplay, Pagination, Navigation, EffectFade, Lazy} from 'swiper';
Swiper.use([Autoplay, Pagination, Lazy, Navigation, EffectFade]);


class Slideshow {
    
  constructor(elem, hasArrows, args) {

		this.args = args;
	
		this.elem = elem;
		this.hasArrows = this.args.hasArrows;

		this.slidesCount = this.elem.children[0].children.length;

		if (window.innerWidth < 1050) {
			this.args.slidesPerView = 1;
		}

		this.slideshow = new Swiper(elem, {
			direction: this.args.direction,
			loop: this.args.loop,
			slidesPerView: this.args.slidesPerView,
			spaceBetween: this.args.spaceBetween,
			centeredSlides: this.args.centeredSlides,
			autoplay: this.args.autoplay,
			effect: this.args.effect,
			pagination: false,
			preventClicksPropagation: this.args.preventClicksPropagation,
			preventClicks: this.args.preventClicks,
			simulateTouch: this.args.simulateTouch,
			autoHeight: this.args.autoHeight,
			preloadImages: this.args.preloadImages,
			lazy: this.args.lazy,
			slideClass: this.args.slideClass,
		});

		if (this.args.captions != null) {
			this.captionChildren = this.args.captions.children;
			// for (let i = 0; i < captionChildren.length; i++) {
			// 	captionChildren[i].style.backgroundColor = "green";
			// }


		}

		this.slideshow.on('slideChange', () => {
			this.onSlideChange()
		});

        if (this.hasArrows) {

			this.elem.parentElement.insertAdjacentHTML('beforeend', `

					<div class="slider__prevarr">
						<div class="arr">
							<-
						</div>
					</div>
					<div class="slider__nextarr">
						<div class="arr">
							->
						</div>
					</div>
					<div class="slider__caption">1/` + this.slidesCount + `</div>

			`);
			

			this.rightArr = this.elem.parentElement.children[2];
			this.rightArr.addEventListener("click", () => {
				this.slideshow.slideNext();
			});

			this.leftArr = this.elem.parentElement.children[1];
			this.leftArr.addEventListener("click", () => {
				this.slideshow.slidePrev();
			});
		}
		
	}


	sliderResize() {
		this.slideshow.updateSize()
	}

	onSlideChange() {

		this.counter = this.elem.parentElement.children[3];
		if (this.counter) {

			this.counter.innerHTML = this.slideshow.realIndex+1 + "/" + this.slidesCount;
		}

		// let getcaption = "";
		// if (this.slideshow.slides[this.slideshow.realIndex].children.length > 1) {
		// 	getcaption = this.slideshow.slides[this.slideshow.realIndex].children[1].innerHTML;
		// } 
		
		// this.currcaption.innerHTML = getcaption;
		

		// for (let i = 0; i < this.slidesCount; i++) {
		// 	if (this.args.captions != null) {
		// 		if (i == this.slideshow.realIndex) {
		// 			if (this.captionChildren[i]) this.captionChildren[i].classList.add("act-slide");
		// 		} else {
		// 			if (this.captionChildren[i]) this.captionChildren[i].classList.remove("act-slide");
		// 		}
		// 	}
		// }
	}

	
}
/*
class SlideshowWithCaption extends Slideshow {

	constructor(elem, hasArrows, args, caption) {

		super(elem, hasArrows, args);

		// Set the caption container if it's given
		if (caption != undefined) {
			this.currcaption = caption;
		} else {
			this.currcaption = this.elem.parentElement.children[1].children[0];
		}

		if (this.elem.parentElement.children[1]){
			this.slidenums = this.elem.parentElement.children[1].children[1];
		}

		this.onInit();

		this.slideshow.on('slideChange', () => {
			this.onSlideChange()
		});

		this.events();
	}

	onInit() {
		
		let sliderCounter = "";
		for (let i = 0; i < this.slidesCount; i++) {
			let actslide = ( i == 0) ? "act-slide" : "";
			sliderCounter += "<div class='" + actslide + "'><span>" + (i+1) + "</span></div>";
		}

		if(this.elem.parentElement.children[1].children.length > 1) {
			this.elem.parentElement.children[1].children[1].innerHTML = sliderCounter;
		}

		if(this.elem.children[0].children[0].children[1]) {
			let getcaption = this.elem.children[0].children[0].children[1].innerHTML;
			this.currcaption.innerHTML = getcaption;
			
		}

	}

	events() {
		for (let i = 0; i < this.slidesCount; i++) {
			if (this.slidenums && this.slidenums.children.length > 0) this.slidenums.children[i].addEventListener("click", () => this.goToSlide(i))
		}
	}

	goToSlide(i) {
		console.log("goto" + i)
		this.slideshow.slideTo(i,300);
	}

	
	onSlideChange() {

		let getcaption = "";
		if (this.slideshow.slides[this.slideshow.realIndex].children.length > 1) {
			getcaption = this.slideshow.slides[this.slideshow.realIndex].children[1].innerHTML;
		} 
		
		this.currcaption.innerHTML = getcaption;
		

		for (let i = 0; i < this.slidesCount; i++) {
			
			if (i == this.slideshow.realIndex) {
				this.slidenums.children[i].classList.add("act-slide");
			} else {
				this.slidenums.children[i].classList.remove("act-slide");
			}
		}
	}
}

*/

export {Slideshow};
